import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import type { AppState } from "../../../app/store";

export const TimeTableLayout = ({
  dayRows,
  showMoreButton,
  selectAnotherDateButton,
  dayRowWithDatePicker,
}: {
  dayRows: React.ReactNode;
  showMoreButton: React.ReactNode;
  selectAnotherDateButton: React.ReactNode;
  dayRowWithDatePicker: React.ReactNode;
}) => {
  const { endDate } = useSelector((state: AppState) => state.questPage.quest);

  const { isShowAnotherDateForm, isTimeTableExpanded } = useSelector(
    (state: AppState) => state.questPage
  );

  const selectedRow = useSelector(
    (state: AppState) => state.reservatinForm.rowNumber
  );

  useEffect(() => {
    const element = document.getElementById("reservation-form-id");

    if (!element) return;

    const block = window.innerWidth <= 722 ? "end" : "center";

    element.scrollIntoView({ behavior: "smooth", block: block });
  }, [selectedRow]);

  const anotherDate = !isShowAnotherDateForm
    ? selectAnotherDateButton
    : dayRowWithDatePicker;

  return (
    <div className="timetable">
      {dayRows}
      {!endDate && (!isTimeTableExpanded ? showMoreButton : anotherDate)}
    </div>
  );
};
