export function calcDateOfTimezone(date: Date, offset: number) {
  // convert to msec
  // subtract local time zone offset
  // get UTC time in msec
  var utc = date.getTime() + date.getTimezoneOffset() * 60000;

  // create new Date object for different city
  // using supplied offset
  var nd = new Date(utc + 3600000 * offset);

  return nd;
}
