import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../app/store";
import ReservationForm from "../../reservation-form/reservation-form";
import { DayOfWeek } from "../../../pages/questPage/questPageSlice";
import { replaceDate } from "../../../functions/replaceDate";
import { open } from "../../reservation-form/reservationFormSlice";
import { addDaysToDate } from "../helpers/addDaysToDate";

interface DayRowProps {
  date: Date;
  rowIndex: number;
  startHour: string;
  endHour: string;
  timeStepMinutes: number;
  currentDate?: Date;
}

export const DayRow = React.memo((props: DayRowProps) => {
  const startMinutes = toMinutesFromDayStart(props.startHour);
  let endMinutes = toMinutesFromDayStart(props.endHour);

  const isOpen = useSelector(
    (state: AppState) => state.reservatinForm.rowNumber === props.rowIndex
  );

  //example 10:00 - 01:00
  endMinutes =
    endMinutes > startMinutes
      ? endMinutes
      : endMinutes + toMinutesFromDayStart("24:00");

  const timeItems = _.range(startMinutes, endMinutes + 1, props.timeStepMinutes) //inclusive range
    .map((minutes: number, idx: number) => {
      const timeString = minutesToString(minutes);
      const dateString = dateToString(
        minutes < toMinutesFromDayStart("24:00")
          ? props.date
          : addDaysToDate(props.date, 1)
      );

      //Костыльно
      if (
        props.currentDate !== undefined &&
        getDate(props.date, minutes) < props.currentDate
      ) {
        return <></>;
      }

      return (
        <TimeItem
          key={idx}
          dispalyString={timeString}
          dateTimeString={`${dateString} ${timeString}`}
          dayOfWeek={props.date.getDay()}
          rowIndex={props.rowIndex}
        />
      );
    });

  return (
    <div className={`timetable__row ${isOpen ? "timetable__row_open" : ""}`}>
      <div className="timetable__day-wrapper">
        <DayInfo date={props.date} />
        <div className="timetable__wrapper-time">{timeItems}</div>
      </div>
      {isOpen ? <ReservationForm /> : <React.Fragment />}
    </div>
  );
});

interface DayInfoProps {
  date: Date;
}

const DayInfo = (props: DayInfoProps) => {
  const { day, ruMonth, ruDayWeek } = replaceDate(props.date.toDateString());
  return (
    <span
      className={`timetable__date`}
    >{`${day} ${ruMonth}, ${ruDayWeek}`}</span>
  );
};

interface TimeItemProps {
  dispalyString: string;
  dateTimeString: string;
  rowIndex: number;
  dayOfWeek: DayOfWeek;
}

const TimeItem = (props: TimeItemProps) => {
  const dispatch = useDispatch();
  const isActive = useSelector(
    (state: AppState) =>
      !_.includes(state.questPage.quest.reservations, props.dateTimeString)
  );
  const isSelected = useSelector(
    (state: AppState) =>
      state.reservatinForm.dateTimeString === props.dateTimeString
  );

  return (
    <button
      className={`timetable__button ${
        !isActive
          ? "timetable__button_inactive"
          : isSelected
          ? "timetable__button_selected"
          : ""
      }`}
      disabled={!isActive}
      onClick={() => {
        dispatch(
          open({
            dateTimeString: props.dateTimeString,
            rowNumber: props.rowIndex,
            dayOfWeek: props.dayOfWeek,
          })
        );
      }}
    >
      {props.dispalyString}
    </button>
  );
};

const toMinutesFromDayStart = (time: string) => {
  const [hours, minutes] = parseTimeString(time);

  return hours * 60 + minutes;
};
const parseTimeString = (time: string) => {
  return time.split(":").map((x) => parseInt(x));
};

function minutesToString(minutes: number) {
  return `${("0" + Math.floor((minutes / 60) % 24)).slice(-2)}:${(
    "0" +
    (minutes % 60)
  ).slice(-2)}`;
}

function dateToString(date: Date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

function getDate(date: Date, minutesFromStart: number) {
  const newDate = new Date(
    addDaysToDate(date, Math.floor(minutesFromStart / (24 * 60)))
  );

  const hours = Math.floor((minutesFromStart / 60) % 24);
  const minutes = Math.floor(minutesFromStart % 60);

  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  newDate.setSeconds(0);

  return newDate;
}
