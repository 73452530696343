import React from "react";
import { useDispatch } from "react-redux";
import { setShowAnotherDateForm } from "../../../pages/questPage/questPageSlice";

export const SelectAnotherDateButton = () => {
  const dispatch = useDispatch();

  return (
    <button
      className="timetable__button-more"
      onClick={() => {
        dispatch(setShowAnotherDateForm(true));
      }}
    >
      {" "}
      Выбрать другую дату{" "}
    </button>
  );
};
