import React from "react";
import classNames from "classnames";
import {
  CharacteristicsItem,
  CharacteristicsItemProps,
} from "./ui/characteristics-item";

import "./characteristics.scss";

export const Characteristics = ({
  items,
  singUp = true,
  className,
}: {
  items: CharacteristicsItemProps[];
  singUp?: boolean;
  className?: string;
}) => {
  return (
    <ul className={classNames("characteristics", className)}>
      {items.map(({ title, content, info, className }, index) => {
        if (index === 3) {
          return singUp ? (
            <CharacteristicsItem
              key={index}
              title={title}
              content={content}
              info={info}
              className={className}
            />
          ) : null;
        }

        return (
          <CharacteristicsItem
            key={index}
            title={title}
            content={content}
            info={info}
            className={className}
          />
        );
      })}
    </ul>
  );
};
