import React from "react";
import { useDispatch, useSelector } from "react-redux";
import type { AppState } from "../../../app/store";
import {
  changeAnotherDateSelected,
  DayOfWeek,
} from "../../../pages/questPage/questPageSlice";
import { DayRow } from "./dayrow";
import { close } from "../../reservation-form/reservationFormSlice";

interface DayRowWithDatepickerProps {
  openHours: any;
  rowIndex: number;
  timeStepMinutes: number;
  minimumDate: Date;
}

export const DayRowWithDatepicker = (props: DayRowWithDatepickerProps) => {
  const dispatch = useDispatch();
  const minimumDateString = props.minimumDate.toISOString().split("T")[0]; //dateToString
  const dateString = useSelector((state: AppState) =>
    state.questPage.AnotherDateSelected === ""
      ? minimumDateString
      : state.questPage.AnotherDateSelected
  );
  const date = new Date(dateString);
  const dayOfWeek = date.getDay() as DayOfWeek;
  const openHour = props.openHours[dayOfWeek];

  return (
    <div className="timetable__wrapper-date-selector">
      <label htmlFor="date">Выберите дату:</label>
      <input
        className="timetable__date-selector"
        id="date"
        type="date"
        min={minimumDateString}
        value={dateString}
        onChange={(e: any) => {
          dispatch(changeAnotherDateSelected(e.target.value));
          dispatch(close());
        }}
      />
      <DayRow
        {...props}
        date={date}
        startHour={openHour.from}
        endHour={openHour.to}
      />
    </div>
  );
};
