import React from "react";
import { useSelector } from "react-redux";

import { TimeTableLayout } from "./ui/timetable-layout";
import { DayRows } from "./ui/dayrows";
import { calcDateOfTimezone } from "./helpers/calcDateofTimezone";
import { addDaysToDate } from "./helpers/addDaysToDate";
import { ShowMoreButton } from "./ui/showmore-button";
import { SelectAnotherDateButton } from "./ui/select-another-date-button";
import { DayRowWithDatepicker } from "./ui/dayrow-wth-date-picker";

import type { AppState } from "../../app/store";

import "./timetable.scss";

export function TimeTable() {
  const {
    startingDate,
    timeTableDaysShow,
    timezone,
    openHours,
    prepareTimeMinutes,
    durationTimeMinutes,
    endDate,
  } = useSelector((state: AppState) => state.questPage.quest);

  const dates = [];

  const startDate =
    startingDate && new Date(startingDate) > new Date()
      ? new Date(startingDate)
      : new Date();

  const timeStepMinutes = prepareTimeMinutes + durationTimeMinutes;

  const daysFromStartToEnd = endDate
    ? Math.ceil(
        (new Date(endDate).getTime() - startDate.getTime()) /
          (1000 * 60 * 60 * 24)
      ) + 1
    : Number.MAX_VALUE;

  const days =
    timeTableDaysShow < daysFromStartToEnd
      ? timeTableDaysShow
      : daysFromStartToEnd;

  for (let i = 0; i < days; i++) {
    const date = calcDateOfTimezone(
      addDaysToDate(startDate, i),
      parseInt(timezone)
    );
    date.setHours(0, 0, 0, 0);
    dates.push(date);
  }

  return (
    <TimeTableLayout
      dayRows={<DayRows dates={dates} timeStepMinutes={timeStepMinutes} />}
      showMoreButton={
        <ShowMoreButton newtimeTableDaysShow={timeTableDaysShow * 2} />
      }
      selectAnotherDateButton={<SelectAnotherDateButton />}
      dayRowWithDatePicker={
        <DayRowWithDatepicker
          minimumDate={calcDateOfTimezone(
            addDaysToDate(startDate, dates.length),
            parseInt(timezone)
          )}
          rowIndex={dates.length} //+1 ?
          openHours={openHours}
          timeStepMinutes={timeStepMinutes}
        />
      }
    />
  );
}
