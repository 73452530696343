import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { hiddenMobileMenu } from "../pagesSlice";
import { CertificateHeader } from "../../features/certificate-header/certificate-header";
import Modal from "../../features/modal/Modal";
import Form from "../../features/form/Form";
import { open } from "../../features/form/formSlice";
import Spinner from "../../components/spinner/Spinner";

import type { AppState } from "../../app/store";

import "./certificatePage.scss";
import { Button } from "../../components/button/button";

function CertificatePage() {
  const { isWaiting } = useSelector((state: AppState) => state.form);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hiddenMobileMenu());
  }, [dispatch]);

  const price = "900";

  return (
    <>
      <CertificateHeader price={price} />
      <section className="certificate">
        <h2 className="certificate-title">
          Думаете, как порадовать близких или коллег по работе?
        </h2>
        <p className="certificate-description">
          Сертификат от Isolation - это оригинальный подарок в виде бурных
          эмоций и ярких впечатлений! Такой сюрприз точно не оставит ваших
          друзей равнодушными.
        </p>

        <Button
          className="certificate__button"
          onClick={() => dispatch(open())}
        >
          Заказать сертификат
        </Button>
      </section>

      <Modal>
        <Form price={price} text="заказать сертификат" page="Сертификаты" />
      </Modal>

      {isWaiting ? <Spinner /> : null}
    </>
  );
}

export default CertificatePage;
