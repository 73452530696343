import classNames from "classnames";
import React from "react";

import "./button.scss";

export function Button({
  children,
  onClick,
  className,
}: {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
}) {
  return (
    <button className={classNames("button", className)} onClick={onClick}>
      {children}
    </button>
  );
}
