import React from "react";
import { useDispatch } from "react-redux";
import {
  setTimeTableDaysShow,
  setIsTimeTableExpanded,
} from "../../../pages/questPage/questPageSlice";

interface ShowMoreButtonProps {
  newtimeTableDaysShow: number;
}

export const ShowMoreButton = (props: ShowMoreButtonProps) => {
  const dispatch = useDispatch();
  return (
    <button
      className="timetable__button-more"
      onClick={() => {
        dispatch(setTimeTableDaysShow(props.newtimeTableDaysShow));
        dispatch(setIsTimeTableExpanded(true));
      }}
    >
      {" "}
      Показать больше{" "}
    </button>
  );
};
