import { push } from "connected-react-router";
import { store as storeNotify } from "react-notifications-component";
import { reserve } from "../features/reservation-form/reservationFormSlice";

export const asyncMiddleware =
  (axiosInstance: any) => (store: any) => (next: any) => (action: any) => {
    if (action.isAsync) {
      const baseActionType = action.type.slice(0, -5);
      store.dispatch({ type: baseActionType + "Begin" });
      axiosInstance
        .request({
          url: action.url,
          method: action.method,
          data: action.data,
        })
        .then((result: any) => {
          const data = action.resultTransform
            ? action.resultTransform(result)
            : result.data;
          store.dispatch({ type: baseActionType, payload: data });
          if (action.successMessage)
            storeNotify.addNotification({
              title: "Успешно",
              message: action.successMessage,
              type: "success",
              insert: "bottom",
              container: "bottom-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
              },
            });

          if (baseActionType === reserve.type) {
            localStorage.setItem(
              "quest-confirmation",
              JSON.stringify(action.data)
            );
            store.dispatch(push("/confirmation"));
          }
        })
        .catch((error: any) => {
          store.dispatch({ type: baseActionType + "Error", payload: error });
          storeNotify.addNotification({
            title: "Ошибка!",
            message: action.errorMessage
              ? action.errorMessage
              : "Ошибка связи с сервером",
            type: "danger",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
        });
    }

    return next(action);
  };

export interface AsyncAction {
  type: string;
  isAsync: true;
  url: string;
  method: string;
  data?: any;
  successMessage: string;
  errorMessage: string;
  resultTransform?: (result: any) => void;
}

export const createAsyncAction = (
  type: string,
  url: string,
  method: string,
  data?: any,
  successMessage: string = "",
  errorMessage: string = "",
  resultTransform?: (result: any) => void
): AsyncAction => {
  return {
    type: type + "Async",
    isAsync: true,
    url,
    method,
    data,
    successMessage,
    errorMessage,
    resultTransform,
  };
};
