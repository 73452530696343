import { AppState } from "../../app/store";
import { connect } from "react-redux";
import {
  setPersonName,
  setparticipantsCount,
  setPhoneNumber,
  reserve,
  close,
} from "./reservationFormSlice";
import React from "react";
import { createAsyncAction } from "../../middleware/asyncMiddleware";
import "./reservation-form.scss";
import "../../../node_modules/animate.css/animate.css";
import { DayOfWeek } from "../../pages/questPage/questPageSlice";

interface ReservationFormProps {
  close(): void;
  isWaiting: boolean;
  reserve(
    questId: number,
    dateTimeString: string,
    personName: string,
    phoneNumber: string,
    participantsCount: number,
    shownPrice: number,
    questName: string,
    address: string
  ): void;
  setparticipantsCount(value: any): void;
  setPhone(value: any): void;
  setName(value: any): void;
  personName: string;
  phoneNumber: string;
  dateTimeString: string;
  dayOfWeek: DayOfWeek;
  participantsCount: number;
  // pricePerPersonFrom: number;
  priceHours: {
    [day in DayOfWeek]: { from: string; to: string; price: number }[];
  };
  questId: number;
  participantsCountBoarders: { from: number; to: number };
  basePrice: number;
  questName: string;
  address: string;
}
// animate__fadeInDown animate__delay-1s
const ReservationForm = (props: ReservationFormProps) => {
  let priceNotice: React.ReactNode | null = null;

  if (isLateWeekend(props.dayOfWeek, props.dateTimeString)) {
    priceNotice = (
      <p className="reservation-form__price-notice">
        В выходной день и за поздний сеанс к общей стоимости добавляется 2000₽
      </p>
    );
  } else if (isLate(props.dateTimeString)) {
    priceNotice = (
      <p className="reservation-form__price-notice">
        За поздний сеанс к общей стоимости добавляется 1000₽
      </p>
    );
  } else if (isWeekend(props.dayOfWeek)) {
    priceNotice = (
      <p className="reservation-form__price-notice">
        в выходной день к общей стоимости добавляется 1000₽
      </p>
    );
  }

  return (
    <form
      id="reservation-form-id"
      className={`reservation-form`}
      onSubmit={(e) => {
        e.preventDefault();

        const {
          questId,
          dateTimeString,
          personName,
          phoneNumber,
          participantsCount,
          priceHours,
          dayOfWeek,
          questName,
          address,
          reserve,
        } = props;

        reserve(
          questId,
          dateTimeString,
          personName,
          phoneNumber,
          participantsCount,
          countTotalPrice(
            participantsCount,
            dateTimeString,
            priceHours,
            dayOfWeek
          ),
          questName,
          address
        );
      }}
    >
      <div className="reservation-form__wrapper-text">
        <span className="reservation-form__text">Бронирование</span>
        <span className="reservation-form__date">{props.dateTimeString}</span>
      </div>
      <div className="reservation-form__wrapper-input">
        <div className="reservation-form__wrapper-top-input">
          <p className="reservation-form__wrapper-lable-input">
            <label className="reservation-form__label">Ваше имя</label>
            <input
              required
              className="reservation-form__input"
              defaultValue={props.personName}
              placeholder="Имя"
              type="text"
              onChange={(e: any) => props.setName(e.target.value)}
            />
          </p>
          <p className="reservation-form__wrapper-lable-input">
            <label className="reservation-form__label">Ваш телефон</label>
            <input
              required
              minLength={10}
              type="text"
              className="reservation-form__input"
              id="phone"
              defaultValue={props.phoneNumber}
              placeholder="Телефон"
              onChange={(e: any) => props.setPhone(e.target.value)}
            />
          </p>
          <p className="reservation-form__wrapper-lable-input">
            <label className="reservation-form__label" htmlFor="#players">
              Кол-во участников
            </label>
            <input
              required
              className="reservation-form__input"
              //   minLength={props.participantsCountBoarders.from}
              //   maxLength={props.participantsCountBoarders.to}
              id="players"
              defaultValue={props.participantsCount}
              placeholder="Кол-во"
              type="number"
              min={props.participantsCountBoarders.from}
              max={props.participantsCountBoarders.to}
              onChange={(e: any) => props.setparticipantsCount(e.target.value)}
            />
          </p>
        </div>
        <div className="reservation-form__wrapper-bottom-input">
          {/*{getPricePerPerson(props.priceHours, props.dateTimeString) ? <p className='reservation-form__price'>Сумма: <span className='reservation-form__price-rub'>{props.participantsCount*getPricePerPerson(props.priceHours, props.dateTimeString)} РУБ</span></p>: <React.Fragment/>}*/}
          <div>
            <p className="reservation-form__price">
              Цена от:
              <span className="reservation-form__price-rub">
                {props.basePrice} РУБ за 2-3 участника
              </span>
            </p>
            {priceNotice}
          </div>
          <div className="reservation-form__wrapper-button">
            {!props.isWaiting ? (
              <button type="submit" className="reservation-form__button">
                {" "}
                Бронировать{" "}
              </button>
            ) : (
              <React.Fragment />
            )}
            <span
              className="reservation-form__close"
              onClick={() => props.close()}
            >
              Отменить
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state: AppState) => {
  const {
    id,
    participantsCount,
    priceHours,
    pricePerPersonFrom,
    basePrice,
    addressLine,
    title,
  } = state.questPage.quest;

  return {
    ...state.reservatinForm,
    questId: id,
    participantsCountBoarders: participantsCount,
    priceHours,
    pricePerPersonFrom,
    basePrice,
    address: addressLine,
    questName: title,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    close: () => dispatch(close()),
    setName: (name: string) => dispatch(setPersonName(name)),
    setPhone: (phone: string) => dispatch(setPhoneNumber(phone)),
    setparticipantsCount: (count: number) =>
      dispatch(setparticipantsCount(count)),
    reserve: (
      questId: number,
      dateTimeString: string,
      personName: string,
      phoneNumber: string,
      participantsCount: number,
      shownPrice: number,
      questName: string,
      address: string
    ) =>
      dispatch(
        createAsyncAction(
          reserve.type,
          `quests/${questId}/reserve`,
          "POST",
          {
            dateTimeString: dateTimeString,
            personName: personName,
            phoneNumber: phoneNumber,
            participantsCount: participantsCount,
            shownPrice: shownPrice,
            questName,
            address,
          },
          `Бронирование на ${dateTimeString} успешно, ожидайте звонок оператора`,
          "Произошла ошибка при бронировании"
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationForm);

function isLate(dateTimeString: string) {
  const timeString = dateTimeString.split(" ")[1];
  const [hours, minutes] = timeStringToHoursMinutesNumbers(timeString);
  const currentFromMidnight = fromMidnight(hours, minutes);

  const lateStart = 22 * 60;
  const lateEnd = 60;

  return currentFromMidnight >= lateStart || currentFromMidnight <= lateEnd;
}

function isLateWeekend(dayOfWeek: DayOfWeek, dateTimeString: string) {
  return (
    [DayOfWeek.Friday, DayOfWeek.Saturday, DayOfWeek.Sunday].includes(
      dayOfWeek
    ) && isLate(dateTimeString)
  );
}

function isWeekend(dayOfWeek: DayOfWeek) {
  return [DayOfWeek.Saturday, DayOfWeek.Sunday].includes(dayOfWeek);
}

function countTotalPrice(
  personCount: number,
  dateTimeString: string,
  priceHours: any,
  dayOfWeek: DayOfWeek
) {
  const timeString = dateTimeString.split(" ")[1];
  const extraPersons = personCount - 3 > 0 ? personCount - 3 : 0;

  const dayPriceHours = priceHours[dayOfWeek] as Array<{
    from: string;
    to: string;
    price: number;
  }>;

  const basePrice = dayPriceHours.filter(({ from, to }) => {
    const [startHours, startMinutes] = timeStringToHoursMinutesNumbers(from);
    const [endHours, endMinutes] = timeStringToHoursMinutesNumbers(to);
    const [currentHours, currentMinutes] =
      timeStringToHoursMinutesNumbers(timeString);

    const startFromMidnight = fromMidnight(startHours, startMinutes);

    const currentFromMidnight =
      startFromMidnight > fromMidnight(currentHours, currentMinutes)
        ? fromMidnight(currentHours, currentMinutes) + 24 * 60
        : fromMidnight(currentHours, currentMinutes);

    const endFromMidnight =
      startFromMidnight > fromMidnight(endHours, endMinutes)
        ? fromMidnight(endHours, endMinutes) + 24 * 60
        : fromMidnight(endHours, endMinutes);

    return (
      currentFromMidnight >= startFromMidnight &&
      currentFromMidnight <= endFromMidnight
    );
  })[0].price;

  return basePrice + extraPersons * 1100;
}

function timeStringToHoursMinutesNumbers(timeString: string) {
  return timeString.split(":").map((x) => parseInt(x));
}

function fromMidnight(hours: number, minutes: number) {
  return hours * 60 + minutes;
}
