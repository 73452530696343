import React from "react";
import { DayOfWeek } from "../../../pages/questPage/questPageSlice";
import { DayRow } from "./dayrow";
import { useSelector } from "react-redux";
import type { AppState } from "../../../app/store";
import { calcDateOfTimezone } from "../helpers/calcDateofTimezone";

export const DayRows = ({
  dates,
  timeStepMinutes,
}: {
  dates: Date[];
  timeStepMinutes: number;
}) => {
  const { openHours, timezone, unavailabilityPeriods } = useSelector(
    (state: AppState) => state.questPage.quest
  );

  const unavailabilityPeriodsToDate = unavailabilityPeriods?.map((up) => ({
    from: new Date(up.from),
    to: new Date(up.to),
  }));

  return (
    <>
      {dates
        .filter(
          (date) =>
            !unavailabilityPeriodsToDate?.some(
              (up) => up.from <= date && date <= up.to
            )
        )
        .map((date: Date, idx: number) => {
          const dayOfWeek = date.getDay() as DayOfWeek;
          const openHour = openHours[dayOfWeek];
          return (
            <DayRow
              key={idx}
              currentDate={calcDateOfTimezone(new Date(), parseInt(timezone))}
              startHour={openHour.from}
              endHour={openHour.to}
              timeStepMinutes={timeStepMinutes}
              rowIndex={idx}
              date={date}
            />
          );
        })}
    </>
  );
};
